import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AppContext } from './context'
import { makeRequest } from './context/utils'
import style from './style/Apptype.module.scss'
import Loading from './Loading'
import Cookies from 'js-cookie'

let photoToken = ""
let videoToken = ""

const Apptype = () => {
    const { typeLoading, setTypeLoading, setShowNavFilter, setShowFilter, setShowMenu, setShowLanguageMenu } = useContext(AppContext)
    const { caseId } = useParams()

    const startApp = (type) => {
        if(type === "video"){
            window.open(`https://superapp.inspektlabs.com/#${videoToken}`)
        }else{
            window.open(`https://superapp.inspektlabs.com/#${photoToken}`)
        }
    }

    const getTokenData = async() => {
        videoToken = ""
        photoToken = ""
        const res = window.top.location.href.split('.')[0].split('/')[2] === "hiib" ? await makeRequest("/getTokenData", {dealerCode: Cookies.get('dealerCode'), caseId}) : await makeRequest("/getUATTokenData", {dealerCode: Cookies.get('dealerCode'), caseId})
        const data = await res.json()
        if(res.status === 200){
            if(data.hiibTokenData[0].type === "Video"){
                videoToken = data.hiibTokenData[0].token
                photoToken = data.hiibTokenData[1].token
            }
            else{
                videoToken = data.hiibTokenData[1].token
                photoToken = data.hiibTokenData[0].token
            }
            setTypeLoading(false)
        }
    }

    useEffect(()=>{
        setShowNavFilter(false)
        setTypeLoading(true)
        setShowFilter(false)
        setShowMenu(false)
        setShowLanguageMenu(false)
        getTokenData()
    },[])

    return (
        <div className={style.container}>
            {typeLoading ? <Loading/> : 
            <div className={style.typeMain}>
                <p> Select the type of inspection you want to continue with. </p>
                <div className={style.optionContainer}>
                    <div onClick={() => startApp("video")}>
                        <p>Video</p>
                    </div>
                    <div onClick={()=> startApp("image")}>
                        <p>Photo</p>
                    </div>
                </div>
            </div>
            } 
        </div>
    )
}

export default Apptype