import { createContext } from "react";

export const AppContext = createContext({
    username: "",
    password: "",
    role: "Master",
    showFilter: false,
    showMenu: false,
    filterApplied: false,
    date: "",
    inspectionId: "",
    agentId: "",
    status: "",
    menuType: 1,
    loginError: false,
    loginErrorMessage: "",
    selectLoading: true,
    reportLoading: true,
    inspectionData: [],
    appTokenData: [],
    inspectionProcessingData: [],
    appCount: 0,
    processingCount:0,
    allCount: 0,
    showNavFilter: false,
    report:{},
    superLink: "",
    caseId: "",
    personaId: "1",
    personaList: [],
    sendingUrlData: false,
    showUrlMessage: false,
    urlMessage: "",
    language: "English",
    showLanguageMenu: false,
    languageList: [],
    hiibTokenList: [],
    hiibApprovedList: [],
    hiibAutoApprovedList: [],
    hiibRejectList: [],
    hiibAutoRejectList: [],
    hiibReviewList: [],
    hiibTokenCount: 0,
    hiibApproveCount: 0,
    hiibAutoApproveCount: 0,
    hiibRejectCount: 0,
    hiibAutoRejectCount: 0,
    hiibReviewCount: 0,
    typeLoading: true
})
