import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { AppContext } from './context'
import Navbar from './Navbar'

const Super = () => {

    const { superLink, setShowNavFilter, setShowFilter, setShowMenu, setShowLanguageMenu } = useContext(AppContext)

    const { token } = useParams()

    useEffect(() =>{
        setShowNavFilter(false)
        setShowFilter(false)
        setShowMenu(false)
        setShowLanguageMenu(false)
    },[])

    return (
        <div>
            <Navbar/>
            <iframe src={superLink} style={{height: "100vh", width: "100vw"}} allow="camera; gyroscope; fullscreen; geolocation *;">

            </iframe>
        </div>
    )
}

export default Super