import { useState } from 'react'

const useAppData = () => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState('Master')
    const [showFilter, setShowFilter] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const [filterApplied, setFilterApplied] = useState(false)
    const [showNavFilter, setShowNavFilter] = useState(false)
    const [date, setDate] = useState("")
    const [inspectionId, setInspectionId] = useState("")
    const [agentId, setAgentId] = useState("")
    const [status, setStatus] = useState("")
    const [menuType, setMenuType] = useState(1)
    const [loginError, setLoginError] = useState(false)
    const [loginErrorMessage, setLoginErrorMessage] = useState("")
    const [selectLoading, setSelectLoading] = useState(true)
    const [reportLoading, setReportLoading] = useState(true)
    const [typeLoading, setTypeLoading] = useState(true)
    const [inspectionData, setInspectionData] = useState([])
    const [appTokenData, setAppTokenData] = useState([])
    const [inspectionProcessingData, setInspectionProcessingData] = useState([])
    const [appCount, setAppCount] = useState(0)
    const [processingCount, setProcessingCount] = useState(0)
    const [allCount, setAllCount] = useState(0)
    const [report, setReport] = useState({})
    const [superLink, setSuperLink] = useState("")
    const [caseId, setCaseId] = useState("")
    const [personaId, setPersonaId] = useState("1")
    const [personaList, setPersonaList] = useState([])
    const [sendingUrlData, setSendingUrlData] = useState(false)
    const [showUrlMessage, setShowUrlMessage] = useState(false)
    const [urlMessage, setUrlMessage] = useState("")
    const [language, setLanguage] = useState("English")
    const [showLanguageMenu, setShowLanguageMenu] = useState(false)
    const [languageList, setLanguageList] = useState([])
    const [hiibTokenList, setHiibTokenList] = useState([])
    const [hiibApprovedList, setHiibApprovedList] = useState([])
    const [hiibAutoApprovedList, setHiibAutoApprovedList] = useState([])
    const [hiibRejectList, setHiibRejectList] = useState([])
    const [hiibAutoRejectList, setHiibAutoRejectList] = useState([])
    const [hiibReviewList, setHiibReviewList] = useState([])
    const [hiibTokenCount, setHiibTokenCount] = useState(0)
    const [hiibApproveCount, setHiibApproveCount] = useState(0)
    const [hiibAutoApproveCount, setHiibAutoApproveCount] = useState(0)
    const [hiibRejectCount, setHiibRejectCount] = useState(0)
    const [hiibAutoRejectCount, setHiibAutoRejectCount] = useState(0)
    const [hiibReviewCount, setHiibReviewCount] = useState(0)
    

    return {
        username, setUsername, password, setPassword, role, setRole, showFilter, setShowFilter, showMenu, setShowMenu, filterApplied, setFilterApplied, date, setDate,
        agentId, setAgentId, inspectionId, setInspectionId, status, setStatus, menuType, setMenuType, loginError, setLoginError, loginErrorMessage, setLoginErrorMessage,
        selectLoading, setSelectLoading, inspectionData, setInspectionData, appTokenData, setAppTokenData, inspectionProcessingData, setInspectionProcessingData, appCount, setAppCount,
        processingCount, setProcessingCount, allCount, setAllCount, reportLoading, setReportLoading, showNavFilter, setShowNavFilter, report, setReport, superLink, setSuperLink,
        caseId, setCaseId, personaId, setPersonaId, personaList, setPersonaList, sendingUrlData, setSendingUrlData, showUrlMessage, setShowUrlMessage, urlMessage, setUrlMessage,
        language, setLanguage, showLanguageMenu, setShowLanguageMenu, languageList, setLanguageList, hiibTokenList, setHiibTokenList, hiibApprovedList, setHiibApprovedList,
        hiibAutoApprovedList, setHiibAutoApprovedList, hiibRejectList, setHiibRejectList, hiibAutoRejectList, setHiibAutoRejectList, hiibReviewList, setHiibReviewList,
        hiibTokenCount, setHiibTokenCount, hiibApproveCount, setHiibApproveCount, hiibAutoApproveCount, setHiibAutoApproveCount, hiibRejectCount, setHiibRejectCount, hiibAutoRejectCount, 
        setHiibAutoRejectCount, hiibReviewCount, setHiibReviewCount, typeLoading, setTypeLoading
    }

}

export default useAppData