import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AppContext } from './components/context';
import useAppData from './components/context/hooks';
import './App.scss';
import Login from './components/Login';
import Select from './components/Select';
import Report from './components/Report';
import Super from './components/Super';
import GenerateUrl from './components/GenerateUrl';
import HiibLogin from './components/HiibLogin';
import HiibSelect from './components/HiibSelect';
import HiibUATSelect from './components/HiibUATSelect';
import HiibUATLogin from './components/HiibUATLogin';
import Apptype from './components/Apptype';
import Apptypeinternal from './components/Apptypeinternal';

const App = () => {

const appData = useAppData()

  return (
    <AppContext.Provider value={appData}>
      <BrowserRouter>
        <Routes>
          <>
            {window.top.location.href.split('.')[0].split('/')[2] === "hiib" ? <Route path="/" element={<HiibLogin/>}/> : window.top.location.href.split('.')[0].split('/')[2] === "hiib-uat" ? <Route path="/" element={<HiibUATLogin/>}/> :<Route path="/" element={<Login/>}/>}
            {window.top.location.href.split('.')[0].split('/')[2] === "hiib" ? <Route path="/inspections" element={<HiibSelect/>}/> : window.top.location.href.split('.')[0].split('/')[2] === "hiib-uat" ? <Route path="/inspections" element={<HiibUATSelect/>}/> : <Route path="/inspections" element={<Select/>}/>}
            <Route path="/inspections/:id" element={<Report/>}/>
            <Route path="/app/:token" element={<Super/>}/>
            <Route path="/generateUrl" element={<GenerateUrl/>}/>
            <Route path="/selection/:caseId" element={<Apptype/>}/>
            <Route path="/selection/internal/:caseId" element={<Apptypeinternal/>}/>
          </>
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  )

}

export default App;
