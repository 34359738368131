import React, { useContext, useEffect }  from 'react'
import Navbar from './Navbar'
import { AppContext } from './context';
import style from './style/Select.module.scss'
import clearIcon from './images/clearIcon.png'
import downArrow from './images/downArrow.png'
import refresh from './images/refresh.png'
import { checkCookies, makeAuthRequest, removeCookies } from './context/utils';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import Cookies from 'js-cookie';
import lang from './language/Select.json'

const Select = () => {

    const { filterApplied, setFilterApplied, selectLoading, setSelectLoading, inspectionData, setInspectionData, setShowFilter,
            appTokenData, setAppTokenData, inspectionProcessingData, setInspectionProcessingData, appCount, setAppCount, setShowMenu,
            processingCount, setProcessingCount, allCount, setAllCount, setDate, setAgentId, setInspectionId, setStatus, setShowNavFilter,
            setSuperLink, language, setShowLanguageMenu, setLanguageList, setLanguage } = useContext(AppContext)
    const navigate = useNavigate()

    const getData = async () => {
        const res = await makeAuthRequest("/getAllInspections", {tlNewId: Cookies.get("tlId"), tlView: Cookies.get("tlView")})
        const data = await res.json()
        if(res.status === 200){
            setInspectionData(data.inspectionData)
            setAppTokenData(data.superAppTokensData)
            setInspectionProcessingData(data.inspectionProcessing)
            setSelectLoading(false)
        }
    }

    const startApp = (link, token) => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                video: false,
                link: link,
              })
            );
        } else {
            // setSuperLink(link)
            // navigate(`/app/${token}`)
            window.open(link, "_blank")
        }
    }

    const clearFilter = () => {
        setSelectLoading(true)
        setDate("")
        setAgentId("")
        setInspectionId("")
        setStatus("")
        setFilterApplied(false)
        getData()
    }

    const convertTime = (inspectionDateTime) => {
        let date = new Date(inspectionDateTime)
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
      
        let ampm = (hours >= 12) ? 'PM' : 'AM'
      
        hours = hours % 12
        hours = (hours === 0) ? 12 : hours
      
        day = (day < 10) ? '0' + day : day
        month = (month < 10) ? '0' + month : month
        hours = (hours < 10) ? '0' + hours : hours
        minutes = (minutes < 10) ? '0' + minutes : minutes
        seconds = (seconds < 10) ? '0' + seconds : seconds
      
        let formattedDate = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + ampm
      
        return formattedDate;
    }

    useEffect(()=>{
        setShowNavFilter(true)
        setAppCount(0)
        setAllCount(0)
        setProcessingCount(0)
        setShowFilter(false)
        setShowMenu(false)
        setShowLanguageMenu(false)
        if(!checkCookies()){
            removeCookies()
            navigate("/")
        }
        else{
            clearFilter()
            if(Cookies.get('language') === "English" || !Cookies.get('language')){
                setLanguageList(["English"])
                setLanguage("English")
            }else{
                setLanguageList([Cookies.get('language'), "English"])
                setLanguage(Cookies.get('language'))
            }
        }
    },[])

    return (
       <div className={style.container}>
            <Navbar/>
            {selectLoading ? <Loading/> : 
            <div className={style.main}>
                <div className={style.cardContainer}>
                    {filterApplied && <div className={style.filterCard}>
                    <p className={style.filterText}>{lang["Filter-Applied"][language]}</p>
                        <div onClick={() => clearFilter()}>
                            <p className={style.clearText}>{lang["Clear"][language]}</p>
                            <img className={style.clearIcon} src={clearIcon} alt="icon"></img>
                        </div>
                    </div>}
                <div className={style.introCard}>
                    <p>{lang["Hello"][language]}, {Cookies.get('client')}</p>
                    <img src={refresh} alt='refresh' onClick={()=>clearFilter()}></img>
                </div>
                <div className={style.card}>
                    <div className={style.cardHeading}>
                        <p className={style.heading1}>{lang["New"][language]} ({appTokenData.length})</p>
                        {appCount !== 0 && <p className={style.heading2} onClick={()=>setAppCount(0)}>-</p>}
                    </div>
                    {appTokenData.map((item, index) => {
                        const date = new Date(item.date)
                        if(index >= 2 + appCount) return
                        if(item.agentId){
                            return (
                                <div className={style.cardData} key={index} onClick={()=>startApp(`${item.appUrl}#${item.token}`, item.token)}>
                                    <div className={style.subData1}>
                                        <p className={style.name}>{item.agentName}</p>
                                        <p className={style.id}>({lang["Agent"][language]} ID: {item.agentId})</p>
                                        <p className={style.time}>{date.toLocaleString()}</p>
                                    </div>
                                    <div className={style.subData2}>
                                        <p className={style.status}>{lang["Status"][language]}</p>
                                        <p className={style.statusValue}>{lang["New"][language]}</p>
                                    </div>
                                </div>
                            )
                        }
                        else if(item.tlId){
                            return (
                                <div className={style.cardData} key={index} onClick={()=>startApp(`${item.appUrl}#${item.token}`, item.token)}>
                                    <div className={style.subData1}>
                                        <p className={style.name}>{item.tlName}</p>
                                        <p className={style.id}>(TL ID: {item.tlId})</p>
                                        <p className={style.time}>{date.toLocaleString()}</p>
                                    </div>
                                    <div className={style.subData2}>
                                        <p className={style.status}>{lang["Status"][language]}</p>
                                        <p className={style.statusValue}>{lang["New"][language]}</p>
                                    </div>
                                </div>
                            )
                        }
                        else{
                            return (
                                <div className={style.cardData} key={index} onClick={()=>startApp(`${item.appUrl}#${item.token}`, item.token)}>
                                    <div className={style.subData1}>
                                        <p className={style.name}>{item.clientId}</p>
                                        <p className={style.id}>({lang["Master"][language]})</p>
                                        <p className={style.time}>{date.toLocaleString()}</p>
                                    </div>
                                    <div className={style.subData2}>
                                        <p className={style.status}>{lang["Status"][language]}</p>
                                        <p className={style.statusValue}>{lang["New"][language]}</p>
                                    </div>
                                </div>
                            )
                        }
                    })}
                    <div className={style.buttonContainer}>
                        {appCount !== 0 && <div className={style.minButton} onClick={()=>setAppCount(0)}>
                            <p>{lang["Minimize-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                        {appTokenData.length > appCount + 2 && <div className={style.allButton} onClick={()=>setAppCount(appCount + 10)}>
                            <p>{lang["View-all-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                    </div>
                </div>

                <div className={style.card}>
                    <div className={style.cardHeading}>
                        <p className={style.heading1}>{lang["Work-In-Progress"][language]} ({inspectionProcessingData.length})</p>
                        {processingCount !== 0 && <p className={style.heading2} onClick={()=>setProcessingCount(0)}>-</p>}
                    </div>
                    {inspectionProcessingData.map((item, index) => {
                        if(index >= 2 + processingCount) return
                        if(item.agentId && item.agentId !== "NA"){
                            return (
                                <div className={style.cardData} key={index}>
                                    <div className={style.subData1}>
                                        <p className={style.name}>{item.inspection_id}</p>
                                        <p className={style.id}>({lang["Agent"][language]} ID: {item.agentId})</p>
                                        <p className={style.time}>{convertTime(item.inspection_date)}</p>
                                    </div>
                                    <div className={style.subData2}>
                                        <p className={style.status}>{lang["Status"][language]}</p>
                                        <p className={style.statusValue}>{lang[item.status][language]}</p>
                                    </div>
                                </div>
                            )
                        }
                        else if(item.tlId && item.tlId !== "NA"){
                            return (
                                <div className={style.cardData} key={index}>
                                    <div className={style.subData1}>
                                        <p className={style.name}>{item.inspection_id}</p>
                                        <p className={style.id}>(TL ID: {item.tlId})</p>
                                        <p className={style.time}>{convertTime(item.inspection_date)}</p>
                                    </div>
                                    <div className={style.subData2}>
                                        <p className={style.status}>{lang["Status"][language]}</p>
                                        <p className={style.statusValue}>{lang[item.status][language]}</p>
                                    </div>
                                </div>
                            )
                        }
                        else{
                            return (
                                <div className={style.cardData} key={index}>
                                    <div className={style.subData1}>
                                        <p className={style.name}>{item.inspection_id}</p>
                                        <p className={style.id}>({lang["Master"][language]}: {item.client_id})</p>
                                        <p className={style.time}>{convertTime(item.inspection_date)}</p>
                                    </div>
                                    <div className={style.subData2}>
                                        <p className={style.status}>{lang["Status"][language]}</p>
                                        <p className={style.statusValue}>{lang[item.status][language]}</p>
                                    </div>
                                </div>
                            )
                        }
                    })}
                    <div className={style.buttonContainer}>
                        {processingCount !== 0 && <div className={style.minButton} onClick={()=>setProcessingCount(0)}>
                            <p>{lang["Minimize-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                        {inspectionProcessingData.length > processingCount + 2 && <div className={style.allButton} onClick={()=>setProcessingCount(processingCount + 10)}>
                            <p>{lang["View-all-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                    </div>
                </div>

                <div className={style.card}>
                    <div className={style.cardHeading}>
                        <p className={style.heading1}>{lang["Completed"][language]} ({inspectionData.length})</p>
                        {allCount !== 0 && <p className={style.heading2} onClick={()=>setAllCount(0)}>-</p>}
                    </div>
                    {inspectionData.map((item, index) => {
                        if(index >= 2 + allCount) return
                        if(item.agentId && item.agentId !== "NA"){
                            return (
                                <div className={style.cardData} key={index} onClick={()=>{
                                    setShowFilter(false)
                                    setShowMenu(false)
                                    setShowLanguageMenu(false)
                                    if(item.status === "Available") navigate(`/inspections/${item.inspection_id}`)
                                }}>
                                    <div className={style.subData1}>
                                        <p className={style.name}>{item.inspection_id}</p>
                                        <p className={style.id}>({lang["Agent"][language]} ID: {item.agentId})</p>
                                        <p className={style.time}>{convertTime(item.inspection_date)}</p>
                                    </div>
                                    <div className={style.subData2}>
                                        <p className={style.status}>{lang["Status"][language]}</p>
                                        <p className={style.statusValue}>{lang[item.status][language]}</p>
                                    </div>
                                </div>
                            )
                        }
                        else if(item.tlId && item.tlId !== "NA"){
                            return (
                                <div className={style.cardData} key={index} onClick={()=>{
                                    setShowFilter(false)
                                    setShowMenu(false)
                                    setShowLanguageMenu(false)
                                    if(item.status === "Available") navigate(`/inspections/${item.inspection_id}`)
                                }}>
                                    <div className={style.subData1}>
                                        <p className={style.name}>{item.inspection_id}</p>
                                        <p className={style.id}>(TL ID: {item.tlId})</p>
                                        <p className={style.time}>{convertTime(item.inspection_date)}</p>
                                    </div>
                                    <div className={style.subData2}>
                                        <p className={style.status}>{lang["Status"][language]}</p>
                                        <p className={style.statusValue}>{lang[item.status][language]}</p>
                                    </div>
                                </div>
                            )
                        }
                        else{
                            return (
                                <div className={style.cardData} key={index} onClick={()=>{
                                    setShowFilter(false)
                                    setShowMenu(false)
                                    setShowLanguageMenu(false)
                                    if(item.status === "Available") navigate(`/inspections/${item.inspection_id}`)
                                }}>
                                    <div className={style.subData1}>
                                        <p className={style.name}>{item.inspection_id}</p>
                                        <p className={style.id}>({lang["Master"][language]}: {item.client_id})</p>
                                        <p className={style.time}>{convertTime(item.inspection_date)}</p>
                                    </div>
                                    <div className={style.subData2}>
                                        <p className={style.status}>{lang["Status"][language]}</p>
                                        <p className={style.statusValue}>{lang[item.status][language]}</p>
                                    </div>
                                </div>
                            )
                        }
                    })}
                    <div className={style.buttonContainer}>
                        {allCount !== 0 && <div className={style.minButton} onClick={()=>setAllCount(0)}>
                            <p>{lang["Minimize-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                        {inspectionData.length > allCount + 2 && <div className={style.allButton} onClick={()=>setAllCount(allCount + 10)}>
                            <p>{lang["View-all-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                    </div>
                </div>
            </div>
        </div>}
       </div>
    )
}

export default Select