import React, { useContext, useEffect, useState } from 'react'
import style from './style/GenerateUrl.module.scss'
import { v4 as uuidv4 } from 'uuid'
import Navbar from './Navbar'
import generate from './images/generate.png'
import { checkCookies, makeAuthRequest, removeCookies } from './context/utils'
import { AppContext } from './context'
import { useNavigate } from 'react-router-dom'
import lang from './language/GenerateUrl.json'
import Cookies from 'js-cookie'
import Loading from './Loading'

const GenerateUrl = () => {

    const { caseId, setCaseId, personaId, setPersonaId, personaList, setPersonaList, setShowNavFilter, sendingUrlData, setSendingUrlData, showUrlMessage, setShowUrlMessage,
            urlMessage, setUrlMessage, setShowFilter, setShowMenu, language, setShowLanguageMenu, setLanguageList, setLanguage } = useContext(AppContext)
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    const getPersonaList = async() => {
        setLoading(true)
        const res = await makeAuthRequest('/getPersonaid', {})
        const data = await res.json()
        if(res.status === 200){
            if(data.status === true){
                setPersonaList(data.data)
                setLoading(false)
            }
        }
    }

    const sendUrlGenData = async() => {
        if(!personaId) return
        if(sendingUrlData) return
        const res = await makeAuthRequest('/generateURL', {caseId, personaId, agentName: Cookies.get('agentName'), tlName: Cookies.get('tlName')})
        setSendingUrlData(true)
        const data = await res.json()
        if(res.status === 200){
            setCaseId("")
            setSendingUrlData(false)
            setShowUrlMessage(true)
            setUrlMessage(data.message)
            setTimeout(() => {
                setShowUrlMessage(false)
                setUrlMessage("")
            }, 10000);
        }else{
            setSendingUrlData(false)
        }
    }

    useEffect(()=>{
        if(window.top.location.href.split('.')[0].split('/')[2] === "hiib" || window.top.location.href.split('.')[0].split('/')[2] === "hiib-uat") return navigate("/inspections")
        setShowNavFilter(false)
        setShowFilter(false)
        setShowMenu(false)
        setShowLanguageMenu(false)
        setPersonaId("")
        if(!checkCookies()){
            removeCookies()
            navigate("/")
        }else{
            getPersonaList()
            if(Cookies.get('language') === "English" || !Cookies.get('language') ){
                setLanguageList(["English"])
                setLanguage("English")
            }else{
                setLanguageList([Cookies.get('language'), "English"])
                setLanguage(Cookies.get('language'))
            }
        }
    },[])

    return (
        <div className={style.container}>
            <Navbar/>
            {loading ? <Loading/> : 
            <div className={style.main}>
                <img src={generate} alt='generate'></img>
                <p>{lang["Generate-URL"][language]}</p>
                <div className={style.dataInput}>
                    <p>{lang["Case"][language]}</p>
                    <div>
                        <input type='text' value={caseId} onChange={(e)=>setCaseId(e.target.value)}/>
                        <p onClick={()=>setCaseId(uuidv4().split('-').join(''))}>Generate</p>
                    </div>
                    <p>Persona ID</p>
                    <select value={personaId} onChange={(e)=>setPersonaId(e.target.value)}>
                        <option value="">Select</option>
                        {personaList.map((item, index) => {
                            return <option key={index} value={item["key"]}>{ item.type ? `${item.key} - ${item.type}`: item.key}</option>
                        })}
                    </select>
                </div>
                <button onClick={()=>sendUrlGenData()}>{lang["Generate-URL"][language]}</button>
                {showUrlMessage && <p style={{color: "red"}}>{urlMessage}</p>}
            </div>}
        </div>
    )
}

export default GenerateUrl