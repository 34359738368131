import React, { useContext }  from 'react'
import { AppContext } from './context';
import { useNavigate } from 'react-router-dom';
import style from "./style/Navbar.module.scss"
import burger from "./images/menuIcon.png"
import filter from "./images/filterIcon.png"
import hiibLogo from './images/hiib-logo.svg'
import inspektLogo from './images/logo.svg'
import { makeAuthRequest, removeCookies } from './context/utils';
import lang from './language/Navbar.json'
import Cookies from 'js-cookie'

const Navbar = () => {

    const { showFilter, setShowFilter, showMenu, setShowMenu, date, setDate, agentId, setAgentId, inspectionId, setInspectionId, status, setStatus, setFilterApplied,
            setInspectionData, setAppTokenData, setInspectionProcessingData, setSelectLoading, showNavFilter, language, setLanguage, showLanguageMenu, setShowLanguageMenu, 
            languageList, setHiibApprovedList, setHiibAutoApprovedList, setHiibRejectList, setHiibAutoRejectList, setHiibReviewList } = useContext(AppContext)
    const navigate = useNavigate()

    const getFilteredData = async () => {
        const res = await makeAuthRequest("/getAllInspections", {inspectionId, agentId, date, status, tlNewId: Cookies.get("tlId"), tlView: Cookies.get("tlView")})
        const data = await res.json()
        if(res.status === 200){
            if(status === "new"){
                setInspectionData([])
                setAppTokenData(data.superAppTokensData)
                setInspectionProcessingData([])
                setSelectLoading(false)
                setShowFilter(false)
                setFilterApplied(true)
                return
            }
            setInspectionData(data.inspectionData)
            setAppTokenData(data.superAppTokensData)
            setInspectionProcessingData(data.inspectionProcessing)
            setSelectLoading(false)
            setShowFilter(false)
            setFilterApplied(true)
        }
    }

    const getHiibFilteredData = async () => {
        const res = window.top.location.href.split('.')[0].split('/')[2] === "hiib" ? await makeAuthRequest("/getHiibData", {dealerCode: Cookies.get('dealerCode'), date, inspectionId}) : await makeAuthRequest("/getHiibUATData", {dealerCode: Cookies.get('dealerCode'), date, inspectionId})
        const res1 = await makeAuthRequest("/getAllInspections", {inspectionId, agentId: Cookies.get('dealerCode'), date})
        const data = await res.json()
        const data1 = await res1.json()
        if(res.status === 200 && res1.status === 200){
            let hiibTokens = []
            for (let i = 0; i < data.hiibTokenData.length; i++) {
                for (let j = i + 1; j < data.hiibTokenData.length; j++) {
                    if(data.hiibTokenData[i].uniqueId === data.hiibTokenData[j].uniqueId){
                        let temp = [data.hiibTokenData[i], data.hiibTokenData[j]]
                        hiibTokens.push(temp)
                        break
                    }
                }
            }
            setAppTokenData(hiibTokens)
            setHiibApprovedList(data.hiibApprovedData)
            setHiibAutoApprovedList(data.hiibAutoApprovedData)
            setHiibRejectList(data.hiibRejectedData)
            setHiibAutoRejectList(data.hiibAutoRejectedData)
            setHiibReviewList(data.hiibReviewData)
            setInspectionProcessingData(data1.inspectionProcessing)
            setSelectLoading(false)
            setShowFilter(false)
            setFilterApplied(true)
        }
    }

    const logout = () => {
        removeCookies()
        navigate('/')
        setShowMenu(false)
        setShowFilter(false)
    }

    const applyFilter = () => {
        if(!date && !inspectionId && !agentId && !status){
            return setShowFilter(false)
        }
        if(window.top.location.href.split('.')[0].split('/')[2] === "hiib" || window.top.location.href.split('.')[0].split('/')[2] === "hiib-uat"){
            getHiibFilteredData()
        }else{
            getFilteredData()
        }
    }

    return (
        <div className={style.main} style={(window.top.location.href.split('.')[0].split('/')[2] === "hiib" || window.top.location.href.split('.')[0].split('/')[2] === "hiib-uat") ? {backgroundColor: "#042F5F"} : {backgroundColor: "#202020"}}>
            <div className={style.container}>
                <div>
                    <img src={burger} onClick={()=>{
                        setShowMenu(!showMenu)
                        setShowFilter(false)
                        setShowLanguageMenu(false)
                    }} alt="menu"></img>
                    <img className={style.logo} style={(window.top.location.href.split('.')[0].split('/')[2] === "hiib" || window.top.location.href.split('.')[0].split('/')[2] === "hiib-uat" || Cookies.get("master")==="sogesa") ? {width: "120px"} :{width: "55px"}} src={(window.top.location.href.split('.')[0].split('/')[2] === "hiib" || window.top.location.href.split('.')[0].split('/')[2] === "hiib-uat") ? hiibLogo : Cookies.get("master") === "sogesa" ? "/logo/sogesa.jpg": inspektLogo} onClick={()=>navigate('/inspections')} alt="logo"></img>
                </div>
                <div>
                    {(window.top.location.href.split('.')[0].split('/')[2] !== "hiib" && window.top.location.href.split('.')[0].split('/')[2] !== "hiib-uat") && 
                    <div className={style.language} onClick={()=>{
                        setShowLanguageMenu(!showLanguageMenu)
                        setShowFilter(false)
                        setShowMenu(false)
                        }}>
                        <p>{lang[language][language]}</p>
                    </div>}
                    {showNavFilter && <img src={filter} onClick={()=>{
                        setShowFilter(!showFilter)
                        setShowMenu(false)
                        setShowLanguageMenu(false)
                    }} alt="filter"></img>}
                    {(window.top.location.href.split('.')[0].split('/')[2] !== "hiib" && window.top.location.href.split('.')[0].split('/')[2] !== "hiib-uat") && 
                    <div className={style.url} onClick={()=>navigate('/generateUrl')}>
                        <p>+</p>
                    </div>}
                </div>
            </div>

            {showMenu && <div className={style.menu}>
                <p onClick={()=>navigate('/inspections')}>{lang["Inspections-List"][language]}</p>
                {(window.top.location.href.split('.')[0].split('/')[2] !== "hiib" && window.top.location.href.split('.')[0].split('/')[2] !== "hiib-uat") && <p onClick={()=>navigate('/generateUrl')}>{lang["Generate-URL"][language]}</p>}
                <p onClick={()=>logout()}>{lang["logout"][language]}</p>
            </div>}
            {showLanguageMenu && <div className={style.menu}>
                {languageList.map((item, index) => {
                    return <p key={index} onClick={()=>{
                        setLanguage(item)
                        setShowLanguageMenu(false)
                    }}>{lang[item][language]}</p>
                })}
            </div>}
            {showFilter && <div className={style.filter}>
                <p className={style.filterHeader}>{lang["Filter"][language]}</p>
                <div>
                    <p>{lang["Date"][language]}</p>
                    <input type='date' value={date} onChange={(e) =>setDate(e.target.value)}/>
                </div>
                <div>
                    <p>{lang["Inspection ID/Case ID"][language]}</p>
                    <input type='text' value={inspectionId} onChange={(e) =>setInspectionId(e.target.value)}/>
                </div>
                {(window.top.location.href.split('.')[0].split('/')[2] !== "hiib" && window.top.location.href.split('.')[0].split('/')[2] !== "hiib-uat") && <div>
                    <p>{lang["Agent ID/Agent Name"][language]}</p>
                    <input type='text' value={agentId} onChange={(e) =>setAgentId(e.target.value)}/>
                </div>}
                {(window.top.location.href.split('.')[0].split('/')[2] !== "hiib" && window.top.location.href.split('.')[0].split('/')[2] !== "hiib-uat") && <div>
                    <p>{lang["Status"][language]}</p>
                    <select value={status} onChange={(e)=> setStatus(e.target.value)}>
                        <option value="">{lang["Select"][language]}</option>
                        <option value="new">{lang["New"][language]}</option>
                        <option value="available">{lang["Available"][language]}</option>
                        <option value="processing">{lang["Processing"][language]}</option>
                        <option value="rejected">{lang["Rejected"][language]}</option>
                    </select>
                </div>}
                <button onClick={() => applyFilter()}>{lang["Apply"][language]}</button>
            </div>}
        </div>
    )
}

export default Navbar