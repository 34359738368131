import React, { useContext, useEffect }  from 'react'
import style from "./style/Login.module.scss"
import { AppContext } from './context';
import { useNavigate } from 'react-router-dom';
import inspektLogo from './images/logo.svg'
import inspektAuto from './images/inspektlabs2.png'
import loginCar from './images/car.png'
import start from './images/start.png'
import { makeRequest } from './context/utils';
import Cookies from 'js-cookie'

const Login = () => {

  const { username, password, setUsername, setPassword, role, setRole, loginError, setLoginError, loginErrorMessage, setLoginErrorMessage, setLanguage, setLanguageList } = useContext(AppContext)
  const navigate = useNavigate()

  const sendData = async() => {
    const res = await makeRequest("/login", {userId: username, password: password, role: role})
    const data = await res.json()
    if(res.status === 200){
      Cookies.set('token', data.token, {expires: 0.16})
      Cookies.set('loggedIn', true, {expires: 0.16})
      Cookies.set('client', username, {expires: 0.16})
      Cookies.set('clientType', role, {expires: 0.16})
      Cookies.set('master', data.masterId, {expires: 0.16})
      Cookies.set('tlId', data.tlId, {expires: 0.16})
      Cookies.set('tlView', data.tlView, {expires: 0.16})
      Cookies.set('tlName', data.tlName, {expires: 0.16})
      Cookies.set('agentName', data.agentName, {expires: 0.16})
      if(data.language[0] === "English" || data.language[0] === "None" ){
        Cookies.set('language', "English", {expires: 0.16})
        setLanguageList(["English"])
        setLanguage("English")
      }else{
        setLanguageList([data.language[0], "English"])
        setLanguage(data.language[0])
        Cookies.set('language', data.language[0], {expires: 0.16})
      }
      setLoginError(false)
      setLoginErrorMessage("")
      navigate("/inspections")
    }
    else{
      setLoginError(true)
      setLoginErrorMessage("Invalid username/password/role")
    }
  }

  return (
    <div className={style.container}>
      <div className={style.titleContainer}>
        {window.location.host === "perizia.sogesa.net" ? <img style={{width: "275px", height: "100px"}} src="/logo/sogesa.jpg" alt="logo"></img> :<img src={inspektLogo} alt="logo"></img>}
        {window.location.host !== "perizia.sogesa.net" && <img src={inspektAuto} alt="logo"></img>}
        {window.location.host !== "perizia.sogesa.net" && <div>
          <p>Our products detect damages on items</p>
          <p>using photos & videos, eliminating the need</p>
          <p>for physical inspections</p>
        </div>}
      </div>
      <div className={style.infoContainer}>
        <p>Login</p>
        <select value={role} onChange={e => setRole(e.target.value)}>
          <option value="Master">Master</option>
          <option value="Lead">Team Lead</option>
          <option value="Agent">Agent</option>
        </select>
        <input
          placeholder='Client ID'
          type="text"
          id="username"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <input
          placeholder='Password'
          type="password"
          id="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </div>
      {window.location.host !== "perizia.sogesa.net" && <p>© Copyright 2023 Inspektlabs Inc. All rights reserved.</p>}
      {loginError && <h5>{loginErrorMessage}</h5>}
      <div className={style.loginBtn}>
        <img onClick={()=>sendData()} className={style.startImg} src={start}></img>
        <img className={style.carImg} src={loginCar} alt='img'></img>
      </div>
    </div>
  )
}

export default Login