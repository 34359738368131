import React, { useContext, useEffect }  from 'react'
import Navbar from './Navbar'
import { AppContext } from './context';
import style from './style/Select.module.scss'
import clearIcon from './images/clearIcon.png'
import downArrow from './images/downArrow.png'
import refresh from './images/refresh.png'
import { checkCookies, makeAuthRequest, removeCookies } from './context/utils';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import Cookies from 'js-cookie';
import lang from './language/Select.json'

const HiibUATSelect = () => {

    const { filterApplied, setFilterApplied, selectLoading, setSelectLoading, inspectionData, setInspectionData, setShowFilter,
            appTokenData, setAppTokenData, inspectionProcessingData, setInspectionProcessingData, appCount, setAppCount, setShowMenu,
            processingCount, setProcessingCount, allCount, setAllCount, setDate, setAgentId, setInspectionId, setStatus, setShowNavFilter,
            setSuperLink, language, setShowLanguageMenu, setLanguageList, setLanguage, hiibTokenList, setHiibTokenList, hiibApprovedList, setHiibApprovedList,
            hiibAutoApprovedList, setHiibAutoApprovedList, hiibRejectList, setHiibRejectList, hiibAutoRejectList, setHiibAutoRejectList, hiibReviewList, setHiibReviewList,
            hiibTokenCount, setHiibTokenCount, hiibApproveCount, setHiibApproveCount, hiibAutoApproveCount, setHiibAutoApproveCount, hiibRejectCount, setHiibRejectCount, 
            hiibAutoRejectCount, setHiibAutoRejectCount, hiibReviewCount, setHiibReviewCount } = useContext(AppContext)

    const navigate = useNavigate()

    const getData = async () => {
        const res = await makeAuthRequest("/getAllInspections", {})
        const res1 = await makeAuthRequest("/getHiibUATData", {dealerCode: Cookies.get('dealerCode')})
        const data = await res.json()
        const data1 = await res1.json()
        if(res.status === 200 && res1.status === 200){
            let hiibTokens = []
            for (let i = 0; i < data1.hiibTokenData.length; i++) {
                for (let j = i + 1; j < data1.hiibTokenData.length; j++) {
                    if(data1.hiibTokenData[i].uniqueId === data1.hiibTokenData[j].uniqueId){
                        let temp = [data1.hiibTokenData[i], data1.hiibTokenData[j]]
                        hiibTokens.push(temp)
                        break
                    }
                }
            }
            setAppTokenData(hiibTokens)
            setInspectionProcessingData(data.inspectionProcessing)
            setHiibApprovedList(data1.hiibApprovedData)
            setHiibAutoApprovedList(data1.hiibAutoApprovedData)
            setHiibRejectList(data1.hiibRejectedData)
            setHiibAutoRejectList(data1.hiibAutoRejectedData)
            setHiibReviewList(data1.hiibReviewData)
            setSelectLoading(false)
        }
    }

    const clearFilter = () => {
        setSelectLoading(true)
        setDate("")
        setAgentId("")
        setInspectionId("")
        setStatus("")
        setFilterApplied(false)
        getData()
    }

    const convertTime = (inspectionDateTime) => {
        let date = new Date(inspectionDateTime)
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
      
        let ampm = (hours >= 12) ? 'PM' : 'AM'
      
        hours = hours % 12
        hours = (hours === 0) ? 12 : hours
      
        day = (day < 10) ? '0' + day : day
        month = (month < 10) ? '0' + month : month
        hours = (hours < 10) ? '0' + hours : hours
        minutes = (minutes < 10) ? '0' + minutes : minutes
        seconds = (seconds < 10) ? '0' + seconds : seconds
      
        let formattedDate = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + ampm
      
        return formattedDate;
    }

    useEffect(()=>{
        setShowNavFilter(true)
        setAppCount(0)
        setAllCount(0)
        setProcessingCount(0)
        setShowFilter(false)
        setShowMenu(false)
        setShowLanguageMenu(false)
        if(!checkCookies()){
            removeCookies()
            navigate("/")
        }
        else{
            clearFilter()
            if(Cookies.get('language') === "English" || !Cookies.get('language')){
                setLanguageList(["English"])
                setLanguage("English")
            }else{
                setLanguageList([Cookies.get('language'), "English"])
                setLanguage(Cookies.get('language'))
            }
        }
    },[])

    return (
       <div className={style.container}>
            <Navbar/>
            {selectLoading ? <Loading/> : 
            <div className={style.main}>
                <div className={style.cardContainer}>
                    {filterApplied && <div className={style.filterCard}>
                    <p className={style.filterText}>{lang["Filter-Applied"][language]}</p>
                        <div onClick={() => clearFilter()}>
                            <p className={style.clearText}>{lang["Clear"][language]}</p>
                            <img className={style.clearIcon} src={clearIcon} alt="icon"></img>
                        </div>
                    </div>}
                <div className={style.introCard}>
                    <p>{lang["Hello"][language]}, {Cookies.get("dealerName") ? Cookies.get("dealerName") : Cookies.get('client')}</p>
                    <img src={refresh} alt='refresh' onClick={()=>clearFilter()}></img>
                </div>
                <div className={style.card}>
                    <div className={style.cardHeading}>
                        <p className={style.heading1}>{lang["New"][language]} ({appTokenData.length})</p>
                        {appCount !== 0 && <p className={style.heading2} onClick={()=>setAppCount(0)}>-</p>}
                    </div>
                    {appTokenData.map((item, index) => {
                        if(index >= 2 + appCount) return
                        return (
                        <div className={style.cardData} key={index} onClick={()=>navigate(`/selection/internal/${item[0].caseId}`)}>
                            <div className={style.subData1}>
                                <p className={style.name}>{item[0].ModelName}</p>
                                <p className={style.id}>({item[0].VISoFUniqueReferenceNo})</p>
                                <p className={style.id}>({item[0].RegistrationNo})</p>
                                <p className={style.time}>{convertTime(item[0].inspectionDate)}</p>
                            </div>
                            <div className={style.subData2}>
                                <p className={style.status}>{lang["Status"][language]}</p>
                                <p className={style.statusValue}>{lang["New"][language]}</p>
                            </div>
                        </div>
                        )
                    })}
                    <div className={style.buttonContainer}>
                        {appCount !== 0 && <div className={style.minButton} onClick={()=>setAppCount(0)}>
                            <p>{lang["Minimize-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                        {appTokenData.length > appCount + 2 && <div className={style.allButton} onClick={()=>setAppCount(appCount + 10)}>
                            <p>{lang["View-all-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                    </div>
                </div>


                <div className={style.card}>
                    <div className={style.cardHeading}>
                        <p className={style.heading1}>{lang["Work-In-Progress"][language]} ({inspectionProcessingData.length})</p>
                        {processingCount !== 0 && <p className={style.heading2} onClick={()=>setProcessingCount(0)}>-</p>}
                    </div>
                    {inspectionProcessingData.map((item, index) => {
                        if(index >= 2 + processingCount) return
                        return (
                            <div className={style.cardData} key={index}>
                                <div className={style.subData1}>
                                    <p className={style.name}>{item.inspection_id}</p>
                                    <p className={style.id}>({Cookies.get("dealerName") ? `Dealer Name: ${Cookies.get("dealerName")}`: `master: ${item.client_id}`})</p>
                                    <p className={style.time}>{convertTime(item.inspection_date)}</p>
                                </div>
                                <div className={style.subData2}>
                                    <p className={style.status}>{lang["Status"][language]}</p>
                                    <p className={style.statusValue}>Processing</p>
                                </div>
                            </div>
                        )
                    })}
                    <div className={style.buttonContainer}>
                        {processingCount !== 0 && <div className={style.minButton} onClick={()=>setProcessingCount(0)}>
                            <p>{lang["Minimize-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                        {inspectionProcessingData.length > processingCount + 2 && <div className={style.allButton} onClick={()=>setProcessingCount(processingCount + 10)}>
                            <p>{lang["View-all-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                    </div>
                </div>


                <div className={style.card}>
                    <div className={style.cardHeading}>
                        <p className={style.heading1}>AI Approved ({hiibAutoApprovedList.length})</p>
                        {hiibAutoApproveCount !== 0 && <p className={style.heading2} onClick={()=>setHiibAutoApproveCount(0)}>-</p>}
                    </div>
                    {hiibAutoApprovedList.map((item, index) => {
                        if(index >= 2 + hiibAutoApproveCount) return
                        
                        return (
                            <div className={style.cardData} key={index} onClick={()=>{
                                setShowFilter(false)
                                setShowMenu(false)
                                setShowLanguageMenu(false)
                                navigate(`/inspections/${item.inspectionId}`)
                            }}>
                                <div className={style.subData1}>
                                    <p className={style.name}>{item.inspectionId}</p>
                                    <p className={style.id}>({item.VISoFUniqueReferenceNo})</p>
                                    <p className={style.id}>({item.RegistrationNo})</p>
                                    <p className={style.id}>({Cookies.get("dealerName") ? `${Cookies.get("dealerName")}`: `${item.DealerCode}`})</p>
                                    <p className={style.time}>{convertTime(item.inspectionDate)}</p>
                                </div>
                                <div className={style.subData2}>
                                    <p className={style.status}>{lang["Status"][language]}</p>
                                    <p className={style.statusValue}>AI Approved</p>
                                </div>
                            </div>
                        )
                    })}
                    <div className={style.buttonContainer}>
                        {hiibAutoApproveCount !== 0 && <div className={style.minButton} onClick={()=>setHiibAutoApproveCount(0)}>
                            <p>{lang["Minimize-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                        {hiibAutoApprovedList.length > hiibAutoApproveCount + 2 && <div className={style.allButton} onClick={()=>setHiibAutoApproveCount(hiibAutoApproveCount + 10)}>
                            <p>{lang["View-all-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                    </div>
                </div>


                <div className={style.card}>
                    <div className={style.cardHeading}>
                        <p className={style.heading1}>Approved ({hiibApprovedList.length})</p>
                        {hiibApproveCount !== 0 && <p className={style.heading2} onClick={()=>setHiibApproveCount(0)}>-</p>}
                    </div>
                    {hiibApprovedList.map((item, index) => {
                        if(index >= 2 + hiibApproveCount) return
                        
                        return (
                            <div className={style.cardData} key={index} onClick={()=>{
                                setShowFilter(false)
                                setShowMenu(false)
                                setShowLanguageMenu(false)
                                navigate(`/inspections/${item.inspectionId}`)
                            }}>
                                <div className={style.subData1}>
                                    <p className={style.name}>{item.inspectionId}</p>
                                    <p className={style.id}>({item.VISoFUniqueReferenceNo})</p>
                                    <p className={style.id}>({item.RegistrationNo})</p>
                                    <p className={style.id}>({Cookies.get("dealerName") ? `${Cookies.get("dealerName")}`: `${item.DealerCode}`})</p>
                                    <p className={style.time}>{convertTime(item.inspectionDate)}</p>
                                </div>
                                <div className={style.subData2}>
                                    <p className={style.status}>{lang["Status"][language]}</p>
                                    <p className={style.statusValue}>Approved</p>
                                </div>
                            </div>
                        )
                    })}
                    <div className={style.buttonContainer}>
                        {hiibApproveCount !== 0 && <div className={style.minButton} onClick={()=>setHiibApproveCount(0)}>
                            <p>{lang["Minimize-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                        {hiibApprovedList.length > hiibApproveCount + 2 && <div className={style.allButton} onClick={()=>setHiibApproveCount(hiibApproveCount + 10)}>
                            <p>{lang["View-all-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                    </div>
                </div>


                <div className={style.card}>
                    <div className={style.cardHeading}>
                        <p className={style.heading1}>Auto Rejected ({hiibAutoRejectList.length})</p>
                        {hiibAutoRejectCount !== 0 && <p className={style.heading2} onClick={()=>setHiibAutoRejectCount(0)}>-</p>}
                    </div>
                    {hiibAutoRejectList.map((item, index) => {
                        if(index >= 2 + hiibAutoRejectCount) return
                        
                        return (
                            <div className={style.cardData} key={index} onClick={()=>{
                                setShowFilter(false)
                                setShowMenu(false)
                                setShowLanguageMenu(false)
                                navigate(`/inspections/${item.inspectionId}`)
                            }}>
                                <div className={style.subData1}>
                                    <p className={style.name}>{item.inspectionId}</p>
                                    <p className={style.id}>({item.VISoFUniqueReferenceNo})</p>
                                    <p className={style.id}>({item.RegistrationNo})</p>
                                    <p className={style.id}>({Cookies.get("dealerName") ? `${Cookies.get("dealerName")}`: `${item.DealerCode}`})</p>
                                    <p className={style.time}>{convertTime(item.inspectionDate)}</p>
                                </div>
                                <div className={style.subData2}>
                                    <p className={style.status}>{lang["Status"][language]}</p>
                                    <p className={style.statusValue}>Auto Rejected</p>
                                </div>
                            </div>
                        )
                    })}
                    <div className={style.buttonContainer}>
                        {hiibAutoRejectCount !== 0 && <div className={style.minButton} onClick={()=>setHiibAutoRejectCount(0)}>
                            <p>{lang["Minimize-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                        {hiibAutoRejectList.length > hiibAutoRejectCount + 2 && <div className={style.allButton} onClick={()=>setHiibAutoRejectCount(hiibAutoRejectCount + 10)}>
                            <p>{lang["View-all-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                    </div>
                </div>
                
                
                <div className={style.card}>
                    <div className={style.cardHeading}>
                        <p className={style.heading1}>Rejected ({hiibRejectList.length})</p>
                        {hiibRejectCount !== 0 && <p className={style.heading2} onClick={()=>setHiibRejectCount(0)}>-</p>}
                    </div>
                    {hiibRejectList.map((item, index) => {
                        if(index >= 2 + hiibRejectCount) return
                        
                        return (
                            <div className={style.cardData} key={index} onClick={()=>{
                                setShowFilter(false)
                                setShowMenu(false)
                                setShowLanguageMenu(false)
                                navigate(`/inspections/${item.inspectionId}`)
                            }}>
                                <div className={style.subData1}>
                                    <p className={style.name}>{item.inspectionId}</p>
                                    <p className={style.id}>({item.VISoFUniqueReferenceNo})</p>
                                    <p className={style.id}>({item.RegistrationNo})</p>
                                    <p className={style.id}>({Cookies.get("dealerName") ? `${Cookies.get("dealerName")}`: `${item.DealerCode}`})</p>
                                    <p className={style.time}>{convertTime(item.inspectionDate)}</p>
                                </div>
                                <div className={style.subData2}>
                                    <p className={style.status}>{lang["Status"][language]}</p>
                                    <p className={style.statusValue}>Rejected</p>
                                </div>
                            </div>
                        )
                    })}
                    <div className={style.buttonContainer}>
                        {hiibRejectCount !== 0 && <div className={style.minButton} onClick={()=>setHiibRejectCount(0)}>
                            <p>{lang["Minimize-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                        {hiibRejectList.length > hiibRejectCount + 2 && <div className={style.allButton} onClick={()=>setHiibRejectCount(hiibRejectCount + 10)}>
                            <p>{lang["View-all-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                    </div>
                </div>


                <div className={style.card}>
                    <div className={style.cardHeading}>
                        <p className={style.heading1}>Manual Review ({hiibReviewList.length})</p>
                        {hiibReviewCount !== 0 && <p className={style.heading2} onClick={()=>setHiibReviewCount(0)}>-</p>}
                    </div>
                    {hiibReviewList.map((item, index) => {
                        if(index >= 2 + hiibReviewCount) return
                        
                        return (
                            <div className={style.cardData} key={index} onClick={()=>{
                                setShowFilter(false)
                                setShowMenu(false)
                                setShowLanguageMenu(false)
                                navigate(`/inspections/${item.inspectionId}`)
                            }}>
                                <div className={style.subData1}>
                                    <p className={style.name}>{item.inspectionId ? item.inspectionId: item.caseId}</p>
                                    <p className={style.id}>({item.VISoFUniqueReferenceNo})</p>
                                    <p className={style.id}>({item.RegistrationNo})</p>
                                    <p className={style.id}>({Cookies.get("dealerName") ? `${Cookies.get("dealerName")}`: `${item.DealerCode}`})</p>
                                    <p className={style.time}>{convertTime(item.inspectionDate)}</p>
                                </div>
                                <div className={style.subData2}>
                                    <p className={style.status}>{lang["Status"][language]}</p>
                                    <p className={style.statusValue}>Manual Review</p>
                                </div>
                            </div>
                        )
                    })}
                    <div className={style.buttonContainer}>
                        {hiibReviewCount !== 0 && <div className={style.minButton} onClick={()=>setHiibReviewCount(0)}>
                            <p>{lang["Minimize-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                        {hiibReviewList.length > hiibReviewCount + 2 && <div className={style.allButton} onClick={()=>setHiibReviewCount(hiibReviewCount + 10)}>
                            <p>{lang["View-all-inspections"][language]}</p>
                            <img src={downArrow} alt="arrow"></img>
                        </div>}
                    </div>
                </div>
            </div>
        </div>}
       </div>
    )
}

export default HiibUATSelect