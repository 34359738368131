import Cookies from 'js-cookie'

//non authenticated request
export const makeRequest = async (URL, DATA) => {

    const res = await fetch(URL, { 
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: JSON.stringify(DATA)
    })
    return res
}

//authenticated request
export const makeAuthRequest = async(URL, DATA) => {
  const res = await fetch(URL, { 
    method: 'POST',
    headers: { "Content-Type": "application/json",
               "Authorization": `Bearer ${Cookies.get('token')}` },
    mode: "cors",
    body: JSON.stringify(DATA)
  })
  return res
}

export const checkCookies = () => {
  if(Cookies.get('token') && Cookies.get('loggedIn') === "true" && Cookies.get('client') && Cookies.get('clientType')){
    return true
  }
  return false
}

export const removeCookies = () => {
  Cookies.remove('token')
  Cookies.remove('loggedIn')
  Cookies.remove('client')
  Cookies.remove('clientType')
  Cookies.remove('language')
  Cookies.remove("tlId")
  Cookies.remove("tlView")
  Cookies.remove("tlName")
  Cookies.remove("agentName")
}
