import React from 'react'
import load from './images/loading.gif'

const Loading = () => {
  return (
    <div style={{display: "flex", justifyContent:"center", alignItems: "center", marginTop: "250px"}}>
        <img style={{width: "150px"}} src={load} alt="loading..."></img>
    </div>
  )
}

export default Loading