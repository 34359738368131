import React, { useContext, useEffect, useState } from 'react'
import style from './style/Report.module.scss'
import Navbar from './Navbar'
import arrow from './images/arrow.png'
import clearIcon from './images/cross.png'
import { AppContext } from './context'
import { useNavigate, useParams } from 'react-router-dom'
import { checkCookies, makeAuthRequest, removeCookies } from './context/utils'
import Loading from './Loading'
import Cookies from 'js-cookie'
import lang from './language/Report.json'
import partDamage from './language/partDamage.json'

const Report = () => {

    const { menuType, setMenuType, reportLoading, setReportLoading, setShowNavFilter, report, setReport, setShowFilter, setShowMenu, setShowLanguageMenu, setLanguageList,
            setLanguage, language} = useContext(AppContext)
    const [imageUrl, setImageUrl] = useState("")
    const navigate = useNavigate()
    const { id } = useParams()

    const getReportData = async() => {
        const res = await makeAuthRequest("/getInspectionReport", {inspectionId: id})
        const data = await res.json()
        if(res.status === 200){
            setReport(data)
            setReportLoading(false)
        }
    }

    const downloadReport = async() => {
        const res = await makeAuthRequest("/downloadReport", {inspectionId: id, lang: language})
        const data = await res.json()
        if(res.status === 200){
            setTimeout(()=>window.open(data.url, "_blank"))
        }
    }

    const convertDamage = (damages) => {
        let temp = []
        let nameFound = false
        if(Array.isArray(damages)){
            damages = damages.join(', ')
        }
        if(!damages.split('').includes(",")){
            for(let i = 0; i <partDamage.length; i++){
                if(partDamage[i].English === damages){
                    if(Object.keys(partDamage[i]).includes(language)){
                        nameFound = true
                        return partDamage[i][language]
                    }
                }
            }
            if(!nameFound) return damages
        }else{
            let damageList = damages.split(', ')
            for(let i = 0; i < damageList.length; i++){
                nameFound = false
                for(let j = 0; j <partDamage.length; j++){
                    if(partDamage[j].English === damageList[i]){
                        if(Object.keys(partDamage[j]).includes(language)){
                            nameFound = true
                            temp.push(partDamage[j][language])
                            break
                        }
                    }
                }
                if(!nameFound) temp.push(damageList[i])
            }
            return temp.join(', ')
        }
    }

    const convertPart = (part) => {
        let nameFound = false
        for(let i = 0; i <partDamage.length; i++){
            if(partDamage[i].English === part){
                if(Object.keys(partDamage[i]).includes(language)){
                    nameFound = true
                    return partDamage[i][language]
                }
            }
        }
        if(!nameFound) return part
    }

    const openImage = (imageUrl) => {
        setImageUrl(imageUrl)
    }

    const convertTime = (inspectionDateTime) => {
        let date = new Date(inspectionDateTime)
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
      
        let ampm = (hours >= 12) ? 'PM' : 'AM'
      
        hours = hours % 12
        hours = (hours === 0) ? 12 : hours
      
        day = (day < 10) ? '0' + day : day
        month = (month < 10) ? '0' + month : month
        hours = (hours < 10) ? '0' + hours : hours
        minutes = (minutes < 10) ? '0' + minutes : minutes
        seconds = (seconds < 10) ? '0' + seconds : seconds
      
        let formattedDate = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + ampm
      
        return formattedDate;
    }

    useEffect(()=>{
        setShowNavFilter(false)
        setReportLoading(true)
        setShowFilter(false)
        setShowMenu(false)
        setShowLanguageMenu(false)
        setImageUrl("")
        if(!checkCookies()){
            removeCookies()
            navigate("/")
        }
        else{
            getReportData()
            if(Cookies.get('language') === "English" || !Cookies.get('language') ){
                setLanguageList(["English"])
                setLanguage("English")
            }else{
                setLanguageList([Cookies.get('language'), "English"])
                setLanguage(Cookies.get('language'))
            }
        }
    },[])

    return (
        <div className={style.container}>
            <Navbar/>
            {reportLoading ? <Loading/> : 
            <div className={style.reportContainer}>
                <div className={style.back}>
                    <img onClick={()=>navigate('/inspections')}src={arrow} alt='back'></img>
                    <p onClick={()=>navigate('/inspections')}>{lang["Inspection Results"][language]}</p>
                </div>
                <div className={style.card}>
                    <div className={style.info}>
                        <p className={style.inspection}>{lang["Inspection ID"][language]}</p>
                        <p className={style.inspectionValue}>{report.inspectionData.inspection_id}</p>            
                    </div>
                    <div className={style.info}>
                        <p className={style.date}>{lang["Date"][language]}</p>
                        <p className={style.dateValue}>{convertTime(report.inspectionData.inspection_date)}</p>        
                    </div>
                    <div className={style.info}>
                        <p className={style.status}>{lang["Status"][language]}</p>
                        <p className={style.statusValue}>{(window.top.location.href.split('.')[0].split('/')[2] !== "hiib" && window.top.location.href.split('.')[0].split('/')[2] !== "hiib-uat") ? lang[report.inspectionData.status][language] : report.hiibData[0].status === "RW" ? "Manual Review" : report.hiibData[0].status}</p>           
                    </div>
                    <div className={style.info}>
                        <p className={style.status}>{lang["User"][language]}</p>
                        <p className={style.statusValue}>{(window.top.location.href.split('.')[0].split('/')[2] !== "hiib" && window.top.location.href.split('.')[0].split('/')[2] !== "hiib-uat") ? report.inspectionData.client_id : report.hiibData[0].DealerCode}</p>           
                    </div>
                    <div className={style.info}>
                        <p className={style.reason}>{lang["Reason"][language]}</p>
                        {report.inspectionData.message ? <p className={style.reasonValue}>{report.inspectionData.message}</p> : <p className={style.reasonValue}>----</p> }
                    </div>
                    <div className={style.downloadBtn} onClick={() => downloadReport()}>
                        <p>{lang["Download"][language]}</p>
                    </div>
                    <div className={style.type1}>
                        <p className={style.key}>{lang["License Plate"][language]}</p>
                        {report.inspectionData.license_plate ? <p className={style.value}>{report.inspectionData.license_plate}</p> : <p className={style.value}>----</p> }
                    </div>
                    <div className={style.type2}>
                        <p className={style.key}>{lang["Make"][language]}</p>
                        {(window.top.location.href.split('.')[0].split('/')[2] === "hiib" || window.top.location.href.split('.')[0].split('/')[2] === "hiib-uat") ? <p className={style.value}>{report.hiibData[0].Make}</p> : Object.keys(report.unifiedJson).length !== 0 ? Object.keys(report.unifiedJson.claim).length !== 0 ? <p className={style.value}>{report.unifiedJson.claim.clientRequestData.make}</p>: <p className={style.value}>----</p>: <p className={style.value}>----</p>}
                    </div>
                    <div className={style.type1}>
                        <p className={style.key}>{lang["Model"][language]}</p>
                        {(window.top.location.href.split('.')[0].split('/')[2] === "hiib" || window.top.location.href.split('.')[0].split('/')[2] === "hiib-uat") ? <p className={style.value}>{report.hiibData[0].ModelName}</p> : Object.keys(report.unifiedJson).length !== 0 ? Object.keys(report.unifiedJson.claim).length !== 0 ? <p className={style.value}>{report.unifiedJson.claim.clientRequestData.model}</p>: <p className={style.value}>----</p> : <p className={style.value}>----</p>}
                    </div>
                    <div className={style.type2}>
                        <p className={style.key}>{lang["Odometer"][language]}</p>
                        {Object.keys(report.unifiedJson).length !== 0 ? report.unifiedJson.vehicleReadings.odometerReading ? <p className={style.value}>{report.unifiedJson.vehicleReadings.odometerReading}</p>: <p className={style.value}>----</p>: <p className={style.value}>----</p>}
                    </div>
                    <div className={style.type1}>
                        <p className={style.key}>VIN</p>
                        {Object.keys(report.unifiedJson).length !== 0 ? (report.unifiedJson.vehicleReadings.vinReading && report.unifiedJson.vehicleReadings.vinReading !=="_________________") ? <p className={style.value}>{report.unifiedJson.vehicleReadings.vinReading}</p>: <p className={style.value}>----</p>: <p className={style.value}>----</p>}
                    </div>
                </div>
                {(window.top.location.href.split('.')[0].split('/')[2] === "hiib" || window.top.location.href.split('.')[0].split('/')[2] === "hiib-uat") && <div className={style.card}>
                    <div className={style.type1}>
                        <p className={style.key}>Pre-Inspection Type</p>
                        {<p className={style.value}>{report.hiibData[0].PIType}</p>}
                    </div>
                    <div className={style.type2}>
                        <p className={style.key}>Insurance Company Name</p>
                        {<p className={style.value}>{report.hiibData[0].InsuranceCompanyName}</p>}
                    </div>
                    <div className={style.type1}>
                        <p className={style.key}>Chassis No.</p>
                        {<p className={style.value}>{report.hiibData[0].ChassisNo}</p>}
                    </div>
                    <div className={style.type2}>
                        <p className={style.key}>Registration No.</p>
                        {<p className={style.value}>{report.hiibData[0].RegistrationNo}</p>}
                    </div>
                    <div className={style.type1}>
                        <p className={style.key}>Salutation</p>
                        {<p className={style.value}>{report.hiibData[0].Salutation}</p>}
                    </div>
                    <div className={style.type2}>
                        <p className={style.key}>First Name</p>
                        {<p className={style.value}>{report.hiibData[0].FirstName}</p>}
                    </div>
                    <div className={style.type1}>
                        <p className={style.key}>Last Name</p>
                        {<p className={style.value}>{report.hiibData[0].LastName}</p>}
                    </div>
                    <div className={style.type2}>
                        <p className={style.key}>Company Name</p>
                        {<p className={style.value}>{report.hiibData[0].CompanyName}</p>}
                    </div>
                </div>}
                <div className={style.menuBtn}>
                    <div style={menuType === 1 ? {backgroundColor: "#157AFF"} : {backgroundColor: "#FFFFFF"}} className={style.option1} onClick={()=>setMenuType(1)}>
                        <p style={menuType === 1 ? {color: "#FFFFFF"} : {color: "#010101"}}>{lang["Images"][language]}</p>
                    </div>
                    <div style={menuType === 2 ? {backgroundColor: "#157AFF"} : {backgroundColor: "#FFFFFF"}} className={style.option2} onClick={()=>setMenuType(2)}>
                        <p style={menuType === 2 ? {color: "#FFFFFF"} : {color: "#010101"}}>{lang["Tables"][language]}</p>
                    </div>
                </div>
                {menuType === 1 && <div className={style.imageSec}>
                    {report.relevantImageSections.length !== 0 && report.relevantImageSections.map((item, index) => {
                        return <img key={index} src={item.imageUrl} alt='image' onClick={()=>openImage(item.imageUrl)}></img>
                    })}
                </div>}
                {menuType === 2 && <div className={style.damageSec}>
                    {Object.keys(report.unifiedJson).length !== 0 && report.unifiedJson.preInspection.damagedParts.map((item, index) => {
                        return <div className={style.damageCard} key={index}>
                            <div className={style.head}>
                                <p>#{index+1}</p>
                            </div>
                            <div className={style.damageInfoCard}>
                                <div className={style.damageInfo}>
                                    <p className={style.damageKey}>{lang["Part"][language]}</p>
                                    <p className={style.damageKey}>{convertPart(item.partName)}</p>
                                </div>
                                <div className={style.damageInfo}>
                                    <p className={style.damageKey}>{lang["Damage Type"][language]}</p>
                                    <p className={style.damageKey}>{convertDamage(item.listOfDamages)}</p>
                                </div>
                                <div className={style.damageInfo}>
                                    <p className={style.damageKey}>{lang["Confidence Score"][language]}</p>
                                    <p className={style.damageKey}>{(item.confidenceScore).toFixed(2)}</p>
                                </div>
                                <div className={style.damageInfo}>
                                    <p className={style.damageKey}>{lang["Severity"][language]}</p>
                                    <p className={style.damageKey}>{`${(item.damageSeverityScore * 100).toFixed(1)}%`}</p>
                                </div>
                                <div className={style.damageInfo}>
                                    <p className={style.damageKey}>{lang["Operation"][language]}</p>
                                    <p className={style.damageKey}>{item.laborOperation}</p>
                                </div>
                                <div className={style.damageInfo}>
                                    <p className={style.damageKey}>{lang["Labour Hrs."][language]}</p>
                                    <p className={style.damageKey}>{item.laborRepairUnits}</p>
                                </div>
                            </div>
                        </div>
                    })}
                </div>}
            </div>}
            {imageUrl && <div className={style.imageView}>
                        <img className={style.close} src={clearIcon} alt='image' onClick={()=>setImageUrl("")}></img>
                        <img className={style.mainImage} src={imageUrl} alt='image' ></img>
                    </div>
                }
        </div>
    )
}

export default Report