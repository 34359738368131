import React, { useContext, useEffect, useState }  from 'react'
import style from "./style/HiibLogin.module.scss"
import { AppContext } from './context';
import { useNavigate } from 'react-router-dom';
import hiibLogo from './images/hiib-logo.svg'
import loginCar from './images/car.png'
import start from './images/start.png'
import { makeRequest } from './context/utils';
import Cookies from 'js-cookie'

const HiibUATLogin = () => {

  const { username, password, setUsername, setPassword, role, setRole, loginError, setLoginError, loginErrorMessage, setLoginErrorMessage, setLanguage, setLanguageList } = useContext(AppContext)
  const navigate = useNavigate()

  const sendData = async() => {
    const res = await makeRequest("/hiib-uat-login", {userId: username, password: password, role: role})
    const data = await res.json()
    if(res.status === 200){
      Cookies.set('token', data.token, {expires: 0.16})
      Cookies.set('loggedIn', true, {expires: 0.16})
      Cookies.set('client', username, {expires: 0.16})
      Cookies.set('clientType', role, {expires: 0.16})
      if(data['hiib']['AckStatus']){
        Cookies.set('dealerCode', data['hiib']['DealerCode'], {expires: 0.16})
        Cookies.set('dealerName', data['hiib']['DealerName'], {expires: 0.16})
        Cookies.set('userName', data['hiib']['UserName'], {expires: 0.16})
      }else{
        Cookies.set('dealerCode', "", {expires: 0.16})
        Cookies.set('dealerName', "", {expires: 0.16})
        Cookies.set('userName', "", {expires: 0.16})
      }
      if(data.language[0] === "English" || data.language[0] === "None" ){
        Cookies.set('language', "English", {expires: 0.16})
        setLanguageList(["English"])
        setLanguage("English")
      }else{
        setLanguageList([data.language[0], "English"])
        setLanguage(data.language[0])
        Cookies.set('language', data.language[0], {expires: 0.16})
      }
      setLoginError(false)
      setLoginErrorMessage("")
      navigate("/inspections")
    }
    else{
      setLoginError(true)
      setLoginErrorMessage("Invalid username/password/role")
    }
  }

  const openTerms = (link) => {
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            video: true,
            link: link,
          })
        );
    } else {
        window.open(link, '_blank')
    }
}

  return (
    <div className={style.container}>
      <div className={style.titleContainer}>
        <img src={hiibLogo} alt="logo"></img>
        <div>
          <p>Powered by Inspektlabs</p>
        </div>
      </div>
      <div className={style.infoContainer}>
        {/* <p>Login</p>
        <select value={role} onChange={e => setRole(e.target.value)}>
          <option value="Master">Master</option>
          <option value="Lead">Team Lead</option>
          <option value="Agent">Agent</option>
        </select> */}
        <input
          placeholder='Client ID'
          type="text"
          id="username"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <input
          placeholder='Password'
          type="password"
          id="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </div>
      <p>By logging in you are accepting the <span onClick={()=>{
        openTerms("https://ha.hiib.in/Home/TermsandConditions")
      }}>Terms and conditions</span></p>
      {loginError && <h5>{loginErrorMessage}</h5>}
      <div className={style.loginBtn}>
        <img onClick={()=>sendData()} className={style.startImg} src={start}></img>
        <img className={style.carImg} src={loginCar} alt='img'></img>
      </div>
    </div>
  )
}

export default HiibUATLogin